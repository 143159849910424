export default [
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    rules: 'required',
    type: '',
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    rules: 'required',
    type: '',
  },
  {
    key: 'post',
    label: 'field.channel',
    rules: 'required',
    type: '',
  },
  {
    key: 'digit',
    label: 'field.digit',
    rules: 'required',
    type: 'radio',
    options: [
      {
        text: "field.2DigitNumber",
        value: 2,
      }, {
        text: "field.3DigitNumber",
        value: 3,
      }
    ],
  },
  {
    key: 'minWinAmount',
    label: 'field.minWinAmount',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  // {
  //   key: 'maxWinAmount',
  //   label: 'field.maxWinAmount',
  //   rules: 'required|decimal:2',
  //   type: 'currency',
  // },
  {
    key: 'sellOutPer',
    label: 'field.sellOutPer',
    rules: 'required|decimal:2|max_value:100',
    type: 'text',
  },
]
